import i18next from 'i18next';
import jqueryI18next from 'jquery-i18next';
import XHR from 'i18next-xhr-backend';

import { IService, ServiceType } from './RM2Service';
import { Map } from '..';

declare const $: any;

export class LocalizationServiceOptions implements ILocalizationServiceOptions {
  defaultLanguage: string;
  languageFilesPath: string;

  constructor(opts?: ILocalizationServiceOptions) {
    this.defaultLanguage = 'sl';
    this.languageFilesPath = 'assets/i18n';

    if (opts) {
      if (opts.defaultLanguage != undefined)
        this.defaultLanguage = opts.defaultLanguage;
      
      if (opts.languageFilesPath != undefined)
        this.languageFilesPath = opts.languageFilesPath;
    }
  }
}

export interface ILocalizationServiceOptions {
  defaultLanguage?: string;
  languageFilesPath?: string;
}

export class LocalizationService implements IService {
  private _map: Map;

  public readonly type = ServiceType.Localization;
  public readonly name: string;
  public readonly options: LocalizationServiceOptions;
  public readonly enabled: boolean = true;

  private readonly htmlOptionsAttribute = 'i18n-options';

  private loaded: boolean = false;

  public constructor(map: Map, options: ILocalizationServiceOptions) {
    this._map = map;
    this.options = new LocalizationServiceOptions(options);

    this.init();
  }

  public getLanguage(): string {
    return i18next.language;
  }

  public setLanguage(lang: string) {
    i18next.changeLanguage(lang, () => this.refresh());
  }

  private init() {
    let lang: string = undefined;
    if (localStorage) {
      lang = localStorage.getItem('r-lang');
    }
    i18next.use(XHR).init({
      fallbackLng: 'en',
      lng: lang || this.options.defaultLanguage,
      backend: {
        loadPath: `${this.options.languageFilesPath}/{{lng}}.json`
      }
    }, (err, t) => {
      jqueryI18next.init(i18next, $, {
        optionsAttr: this.htmlOptionsAttribute,
        useOptionsAttr: true
      });

      this.loaded = true;
      this.refresh();
    });
  }

  public refresh(element?: HTMLElement | string) {
    if (this.loaded) {
      if (element == undefined)
        $(this._map.getTarget()).localize();
      
      $(element).localize();
    }
  }

  public mark(element: HTMLElement, key: string, options?: Object) {
    element.setAttribute('data-i18n', key);
    if (options)
      element.setAttribute(this.htmlOptionsAttribute, options.toString());
  }

  public localizeElement(element: HTMLElement, key: string, options?: Object) {
    this.mark(element, key, options);
    this.refresh(element);
  }

  public localize(key: string): string {
    return i18next.t(key);
  }
}
