import { LayerGroup } from './RM2LayerGroup';
import { IService } from '../..';

export class StyleMetadata {
    center: [number, number];
    extent: [[number, number], [number, number]];
    startExtent: [[number, number], [number, number]];
    minZoom: number;
    maxZoom: number;
    minPitch: number;
    maxPitch: number;
    zoom: number;
    tilt: number;
    heading: number;
    persistView: boolean;
    persistLayerSettings: boolean;
    enableRotate: boolean;
    enablePopup: boolean;
    enableRoutingDisclaimer: boolean;
    enableSideScroller: boolean;
    zoomPopup: boolean;
    panPopup: boolean;
    notice: string;
    noticeStyle: string;
    clusterIcons: IClusterIconsData;
    hitTolerance: number;
    hitBuffer: number;
    routeFcdColors: any[];
    groups: LayerGroup[];
    services: IService[];
    controls: any[];
    removeHiddenVectorHybridLayers?: boolean;
    forceReloadSources: IMetadataReloadSourceCfg [];

    constructor(metadata: unknown) {
        this.routeFcdColors = [
            0,
            {
                "light": "#0F3C8F",
                "dark": "#A0B6DF",
                "unselected": "#BBBDBF"
            },
            59,
            {
                "light": "#0F3C8F",
                "dark": "#A0B6DF",
                "unselected": "#BBBDBF"
            },
            60,
            {
                "light": "#F49E00",
                "dark": "#FFAE4B",
                "unselected": "#BB9F8D"
            },
            250,
            {
                "light": "#E2001A",
                "dark": "#F57F7F",
                "unselected": "#A57F7E"
            },
            500,
            {
                "light": "#9E0F00",
                "dark": "#9E3126",
                "unselected": "#874B49"
            }
        ];
        
        this.groups = [];
        this.enablePopup = true;

        this.hitTolerance = 10;
        this.hitBuffer = 5;
        Object.keys(metadata).forEach(k => {
            const val = metadata[k];
            if (val != undefined) {
                if (k == 'groups')
                    val.forEach(g => this.groups.push(new LayerGroup(g)));
                else
                    this[k] = val;
            }
        });

        const md = metadata as IStyleMetadata;
        this.clusterIcons = new ClusterIconsData(md.clusterIcons);
    }

    findGroup(id: string): LayerGroup {
        return this.filterGroups(this.groups, x => x.id === id);
    }

    private filterGroups(groups: LayerGroup[], condition: (g: LayerGroup) => boolean): LayerGroup {
        let found = groups.find(condition);
        if (found)
            return found;
        else {
            const withChildren = groups.filter(g => g.children && g.children.length > 0)
            for (let i = 0; i < withChildren.length; i++) {
                found = this.filterGroups(withChildren[i].children, condition);
                if (found)
                    return found;
            }
        }
        
        return null;
    }
}

export interface IStyleMetadata {
    center: [number, number];
    extent: [[number, number], [number, number]];
    minZoom: number;
    maxZoom: number;
    zoom: number;
    tilt: number;
    heading: number;
    persistView: boolean;
    persistLayerSettings: boolean;
    enableRotate: boolean;
    enablePopup: boolean;
    enableSideScroller: boolean;
    notice: string;
    noticeStyle: string;
    clusterIcons: IClusterIconsData;
    hitTolerance: number;
    groups: LayerGroup[];
    services: IService[];
    controls: any[]; // TODO: DTO
    forceReloadSources: IMetadataReloadSourceCfg [];
}

export class ClusterIconsData implements IClusterIconsData {
    iconSize: number;
    textSize?: number;
    textOffset?: number[];
    textColor?: string;

    constructor(data?: IClusterIconsData) {
        this.iconSize = data && data.iconSize ? data.iconSize : 0.8;
        this.textSize = data && data.textSize ? data.textSize : 14;
        this.textOffset = data && data.textOffset ? data.textOffset : [0.575, -0.625];
        this.textColor = data && data.textColor ? data.textColor : '#FFFFFF';
    }
}

export interface IClusterIconsData {
    iconSize?: number;
    textSize?: number;
    textOffset?: number[];
    textColor?: string;
}

export interface IMetadataReloadSourceCfg {
    sourceId: string;
    intervalMS: number;
}
export class MetadataReloadSourceCfg implements IMetadataReloadSourceCfg {
    sourceId: string;
    intervalMS: number;
}