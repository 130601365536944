/*
notes
- Event infrastructure for RM2

todo
- testiranje
- JSDdoc API dokumentacija
- definiranje EventArgs za vse OL3 evente
*/

import { CoordinateLike } from '../model/RM2Geometry';
import { Map } from '../map/RM2Map';
import { Layer } from './RM2Layer';
import { Feature } from './RM2Feature';

/**
 * Event interface.
 * 
 * @interface IEvent
 * @template T
 */
export interface IRM2Event<T> {
    /**
     * Subscribes event handler.
     *
     * @param {{ (data?: T): void }} handler
     *
     * @memberOf IEvent
     */
    subscribe(handler: (data?: T) => void): void;

    /**
     * Unsubscribes event handler.
     *
     * @param {{ (data?: T): void }} handler
     *
     * @memberOf IEvent
     */
    unsubscribe(handler: (data?: T) => void): void;
}

/**
 * Event object.
 *
 * @class Event
 * @implements {IRM2Event<T>}
 * @template T
 */
export class RM2Event<T> implements IRM2Event<T> {
    private _handlers: ((data?: T) => void)[] = [];

    /**
     * Subscribes event handler.
     *
     * @param {{ (data?: T): void }} handler
     *
     * @memberOf Event
     */
    public subscribe(handler: (data?: T) => void): void {
        this._handlers.push(handler);
    }

    /**
     * Unsubscribes event handler.
     *
     * @param {{ (data?: T): void }} handler
     *
     * @memberOf Event
     */
    public unsubscribe(handler: (data?: T) => void): void {
        this._handlers = this._handlers.filter(h => h !== handler);
    }

    /**
     * Trigger event handlers.
     *
     * @param {T} [data]
     *
     * @memberOf Event
     */
    public trigger(data?: T) {
        this._handlers.slice(0).forEach(h => h(data));
    }

    /**
     * Exposes this event.
     *
     * @returns {IEvent<T>}
     *
     * @memberOf Event
     */
    public expose(): IRM2Event<T> {
        return this;
    }
}


/**
 * MapEventArgs interface.
 */
export interface MapEventArgs {
    map: Map;
    coordinate?: CoordinateLike;
    projection?: string;
    nativeEvent?: Event;
    // layer?: Layer;
    features?: Array<Feature>;
    featureLayers?: Array<Layer>;
}
