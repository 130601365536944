import { Projection, Geometry } from '../';
import { ICameraChangeOptions, CameraChangeOptions, IPanOptions, IFitOptions, PanOptions, FitOptions } from '../model/RM2CameraChangeOptions';
import { Feature } from '../model/RM2Feature';
import { Guid } from 'guid-typescript';
import { Utils } from '../services/RM2StaticService';

export abstract class HighlightOptions implements IHighlightOptions {
    projection?: Projection;
    pan?: boolean;
    // feature?: Feature;
    cameraOptions?: ICameraChangeOptions;

    public static get HighlightTypePropertyName(): string { return 'rmHighlightType'; }

    // Applies the option properties to the feature and returns the feature properties
    public abstract applyOptionsToFeatureProperties(ft?: Feature): any;

    public constructor(opts?: IHighlightOptions) {
        this.projection = opts && opts.projection != undefined ? opts.projection : undefined;
        this.pan = opts && opts.pan != undefined ? opts.pan : true;
        this.cameraOptions = new CameraChangeOptions(opts ? opts.cameraOptions : null);
    }

    public applyFeatureHighlightOptions(opts: IFeatureHighlightOptions) {
        if (opts) {
            for (let fOpt in opts) {
                // if (fOpt in this)
                    this[fOpt] = opts[fOpt];
            }
        }
    }

    public static fromGeoJson(geoJson: any): HighlightOptions {
        // TODO: to ni ok
        if ('icon' in geoJson)
            return new MarkerOptions(geoJson);
        else if ('fillColor' in geoJson)
            return new PolygonOptions(geoJson);
        else if ('lineColor' in geoJson)
            return new LineOptions(geoJson);
        else if ('color' in geoJson)
            return new CircleOptions(geoJson);
    }

    public static highlightTypeToString(type: HighlightType): string {
        return HighlightType[type].toString().toLowerCase();
    }
}
  
export interface IHighlightOptions {
    projection?: Projection;
    pan?: boolean;
    // feature?: Feature;
    cameraOptions?: ICameraChangeOptions;
}

export enum HighlightType {
    Marker = 0,
    Polygon = 1,
    Line = 3,
    Circle = 4
}

export class FeatureHighlightOptions implements IFeatureHighlightOptions {
    projection?: Projection;
    pan?: boolean;
    cameraOptions?: ICameraChangeOptions;

    constructor(opts?: IFeatureHighlightOptions) {
        if (opts) {
            for (let prop in opts)
                this[prop] = opts[prop];
        }

        this.projection = opts && opts.projection != undefined ? opts.projection : undefined;
        this.pan = opts && opts.pan != undefined ? opts.pan : true;
        this.cameraOptions = new CameraChangeOptions(opts ? opts.cameraOptions : null);
    }
}

export interface IFeatureHighlightOptions {
    projection?: Projection;
    pan?: boolean;
    cameraOptions?: ICameraChangeOptions;
}

export class MarkerOptions extends HighlightOptions implements IMarkerOptions {
    icon?: string;
    anchor?: string;
    size?: number;
    offset?: number[];
    zindex?: number;
    cameraOptions?: IPanOptions;

    public static get IconPropertyName(): string { return 'icon'; }
    public static get AnchorPropertyName(): string { return 'anchor'; }
    public static get SizePropertyName(): string { return 'size'; }
    public static get OffsetPropertyName(): string { return 'offset'; }
    public static get ZIndexKeyPropertyName(): string { return 'zindex'; }

    constructor(opts?: IMarkerOptions) {
        super(opts);
        // this.icon = opts && opts.icon != undefined ? opts.icon : 'marker_E2001A-128';
        this.icon = opts && opts.icon != undefined ? opts.icon : 'marker_B-48';
        this.anchor = opts && opts.anchor != undefined ? opts.anchor : 'center';
        this.size = opts && opts.size != undefined ? opts.size : 1;
        this.offset = opts && opts.offset != undefined ? opts.offset : [0, -35];
        this.zindex = opts && opts.zindex != undefined ? opts.zindex : 0;
        this.cameraOptions = new PanOptions(opts ? opts.cameraOptions : null);
    }

    public applyOptionsToFeatureProperties(ft?: Feature): any {
        const type = HighlightOptions.highlightTypeToString(HighlightType.Marker);
        if (ft) {
            if (ft.properties.id == null)
                ft.properties.id = Guid.create().toString();
            
            if (ft.properties[MarkerOptions.IconPropertyName] == null)
                ft.properties[MarkerOptions.IconPropertyName] = this.icon;
            
            if (ft.properties[MarkerOptions.AnchorPropertyName] == null)
                ft.properties[MarkerOptions.AnchorPropertyName] = this.anchor;
            
            if (ft.properties[MarkerOptions.SizePropertyName] == null)
                ft.properties[MarkerOptions.SizePropertyName] = this.size;
        
            if (ft.properties[MarkerOptions.OffsetPropertyName] == null)
                ft.properties[MarkerOptions.OffsetPropertyName] = this.offset;
            
            if (ft.properties[MarkerOptions.ZIndexKeyPropertyName] == null)
                ft.properties[MarkerOptions.ZIndexKeyPropertyName] = this.zindex;
            
            ft.properties[HighlightOptions.HighlightTypePropertyName] = type;
            return ft.properties;
        }
        else {
            const props = { id: Guid.create().toString() };
            props[MarkerOptions.IconPropertyName] = this.icon;
            props[MarkerOptions.AnchorPropertyName] = this.anchor;
            props[MarkerOptions.SizePropertyName] = this.size;
            props[MarkerOptions.OffsetPropertyName] = this.offset;
            props[MarkerOptions.ZIndexKeyPropertyName] = this.zindex;
            props[HighlightOptions.HighlightTypePropertyName] = type;
            return props;
        }
    }
}

export interface IMarkerOptions extends IHighlightOptions {
    icon?: string;
    anchor?: string;
    size?: number;
    offset?: number[];
    zindex?: number;
    cameraOptions?: IPanOptions;
}

export class PolygonOptions extends HighlightOptions implements IPolygonOptions {
    fillColor?: string;
    fillOpacity?: number;
    outlineColor?: string;
    outlineWidth?: number;
    centroid?: Geometry;
    cameraOptions?: IFitOptions;

    public static get FillColorPropertyName(): string { return 'fillColor'; }
    public static get FillOpacityPropertyName(): string { return 'fillOpacity'; }
    public static get OutlineColorPropertyName(): string { return 'outlineColor'; }
    public static get OutlineWidthPropertyName(): string { return 'outlineWidth'; }

    constructor(opts?: IPolygonOptions) {
        super(opts);
        this.fillColor = opts && opts.fillColor != undefined ? opts.fillColor : '#639FFF';
        this.fillOpacity = opts && opts.fillOpacity != undefined ? opts.fillOpacity : 0.4;
        this.outlineColor = opts && opts.outlineColor != undefined ? opts.outlineColor : '#FFFFFF';
        this.outlineWidth = opts && opts.outlineWidth != undefined ? opts.outlineWidth : 5;
        this.cameraOptions = new FitOptions(opts ? opts.cameraOptions : null);
    }

    public applyOptionsToFeatureProperties(ft: Feature): any {
        const type = HighlightOptions.highlightTypeToString(HighlightType.Polygon);
        if (ft) {
            if (ft.properties.id == null)
                ft.properties.id = Guid.create().toString();
            
            if (ft.properties[PolygonOptions.FillColorPropertyName] == null)
                ft.properties[PolygonOptions.FillColorPropertyName] = this.fillColor;
            
            if (ft.properties[PolygonOptions.FillOpacityPropertyName] == null)
                ft.properties[PolygonOptions.FillOpacityPropertyName] = this.fillOpacity;
            
            if (ft.properties[PolygonOptions.OutlineColorPropertyName] == null)
                ft.properties[PolygonOptions.OutlineColorPropertyName] = this.outlineColor;
            
            if (ft.properties[PolygonOptions.OutlineWidthPropertyName] == null)
                ft.properties[PolygonOptions.OutlineWidthPropertyName] = this.outlineWidth;
            
            ft.properties[HighlightOptions.HighlightTypePropertyName] = type;
            return ft.properties;
        }
        else {
            const props = { id: Guid.create().toString() };
            props[PolygonOptions.FillColorPropertyName] = this.fillColor;
            props[PolygonOptions.FillOpacityPropertyName] = this.fillOpacity;
            props[PolygonOptions.OutlineColorPropertyName] = this.outlineColor;
            props[HighlightOptions.HighlightTypePropertyName] = type;
            return props;
        }
    }
}

export interface IPolygonOptions extends IHighlightOptions {
    fillColor?: string;
    fillOpacity?: number;
    outlineColor?: string;
    outlineWidth?: number;
    centroid?: Geometry;
    cameraOptions?: IFitOptions;
}

export class LineOptions extends HighlightOptions implements ILineOptions {
    lineColor?: string;
    lineOpacity?: number;
    lineWidth?: number;
    lineType?: LineType;
    lineJoin?: string;
    lineCap?: string;
    cameraOptions?: IFitOptions;

    public static get LineColorPropertyName(): string { return 'lineColor'; }
    public static get LineOpacityPropertyName(): string { return 'lineOpacity'; }
    public static get LineWidthPropertyName(): string { return 'lineWidth'; }
    public static get LineTypePropertyName(): string { return 'lineType'; }

    constructor(opts?: ILineOptions) {
        super(opts);
        this.lineColor = opts && opts.lineColor != undefined ? opts.lineColor : '#639FFF';
        this.lineOpacity = opts && opts.lineOpacity != undefined ? opts.lineOpacity : 1;
        this.lineWidth = opts && opts.lineWidth != undefined ? opts.lineWidth : 8;
        this.lineType = opts && opts.lineType != undefined ? opts.lineType : LineType.Solid;
        this.lineJoin = opts && opts.lineJoin != undefined ? opts.lineJoin : 'round';
        this.lineCap = opts && opts.lineCap != undefined ? opts.lineCap : 'round';
        this.cameraOptions = new FitOptions(opts ? opts.cameraOptions : null);
    }

    public applyOptionsToFeatureProperties(ft: Feature): any {
        const type = HighlightOptions.highlightTypeToString(HighlightType.Line);
        if (ft) {
            if (ft.properties.id == null)
                ft.properties.id = Guid.create().toString();
            
            if (ft.properties[LineOptions.LineColorPropertyName] == null)
                ft.properties[LineOptions.LineColorPropertyName] = this.lineColor;
            
            if (ft.properties[LineOptions.LineOpacityPropertyName] == null)
                ft.properties[LineOptions.LineOpacityPropertyName] = this.lineOpacity;
            
            if (ft.properties[LineOptions.LineWidthPropertyName] == null)
                ft.properties[LineOptions.LineWidthPropertyName] = this.lineWidth;
    
            if (ft.properties[LineOptions.LineTypePropertyName] == null)
                ft.properties[LineOptions.LineTypePropertyName] = LineOptions.lineTypeToString(this.lineType);
            else if (typeof ft.properties[LineOptions.LineTypePropertyName] === 'number')
                ft.properties[LineOptions.LineTypePropertyName] = LineOptions.lineTypeToString(ft.properties[LineOptions.LineTypePropertyName]);
            
            ft.properties[HighlightOptions.HighlightTypePropertyName] = type;
            return ft.properties;
        }
        else {
            const props = { id: Guid.create().toString() };
            props[LineOptions.LineColorPropertyName] = this.lineColor;
            props[LineOptions.LineOpacityPropertyName] = this.lineOpacity;
            props[LineOptions.LineWidthPropertyName] = this.lineWidth;
            props[LineOptions.LineTypePropertyName] = LineOptions.lineTypeToString(this.lineType);
            props[HighlightOptions.HighlightTypePropertyName] = type;
            return props;
        }
    }

    public static lineTypeToString(type: LineType): string {
        return LineType[type].toString().toLowerCase();
    }
}

export interface ILineOptions extends IHighlightOptions {
    lineColor?: string;
    lineOpacity?: number;
    lineWidth?: number;
    lineType?: LineType;
    lineJoin?: string;
    lineCap?: string;
    cameraOptions?: IFitOptions;
}

export enum LineType {
    None = 0,
    Solid = 1,
    Dotted = 2,
    Dashed = 3,
    LongDashed = 4,
    DashDotted = 5,
    LongDashDotted = 6,
    Custom = 7
}

export class CircleOptions extends HighlightOptions implements ICircleOptions {
    color?: string;
    opacity?: number;
    radius?: number;
    radiusUnitType?: CircleRadiusUnitType;
    outlineColor?: string;
    outlineWidth?: number;
    cameraOptions?: IPanOptions;

    public static get CircleColorPropertyName(): string { return 'color'; }
    public static get CircleOpacityPropertyName(): string { return 'opacity'; }
    public static get CircleRadiusPropertyName(): string { return 'radius'; }
    public static get CircleRadiusUnitTypePropertyName(): string { return 'radiusUnitType'; }
    public static get OutlineColorPropertyName(): string { return 'outlineColor'; }
    public static get OutlineWidthPropertyName(): string { return 'outlineWidth'; }

    constructor(opts?: ICircleOptions) {
        super(opts);
        this.color = opts && opts.color != undefined ? opts.color : '#639FFF';
        this.opacity = opts && opts.opacity != undefined ? opts.opacity : 1;
        this.radius = opts && opts.radius != undefined ? opts.radius : 7;
        this.radiusUnitType = opts && opts.radiusUnitType != undefined ? opts.radiusUnitType : CircleRadiusUnitType.Pixels;
        this.outlineColor = opts && opts.outlineColor != undefined ? opts.outlineColor : '#FFFFFF';
        this.outlineWidth = opts && opts.outlineWidth != undefined ? opts.outlineWidth : 2;
        this.cameraOptions = new PanOptions(opts ? opts.cameraOptions : null)
    }

    public applyOptionsToFeatureProperties(ft: Feature): any {
        const type = HighlightOptions.highlightTypeToString(HighlightType.Circle);
        if (ft) {
            if (ft.properties.id == null)
                ft.properties.id = Guid.create().toString();
            
            if (ft.properties[CircleOptions.CircleColorPropertyName] == null)
                ft.properties[CircleOptions.CircleColorPropertyName] = this.color;
            
            if (ft.properties[CircleOptions.CircleOpacityPropertyName] == null)
                ft.properties[CircleOptions.CircleOpacityPropertyName] = this.opacity;
            
            if (ft.properties[CircleOptions.CircleRadiusPropertyName] == null)
                ft.properties[CircleOptions.CircleRadiusPropertyName] = this.radius;
    
            if (ft.properties[CircleOptions.CircleRadiusUnitTypePropertyName] == null)
                ft.properties[CircleOptions.CircleRadiusUnitTypePropertyName] = CircleOptions.circleRadiusTypeToString(this.radiusUnitType);
            else if (typeof ft.properties[CircleOptions.CircleRadiusUnitTypePropertyName] === 'number')
                ft.properties[CircleOptions.CircleRadiusUnitTypePropertyName] = CircleOptions.circleRadiusTypeToString(ft.properties[CircleOptions.CircleRadiusUnitTypePropertyName]);
            
            if (ft.properties[CircleOptions.OutlineColorPropertyName] == null)
                ft.properties[CircleOptions.OutlineColorPropertyName] = this.outlineColor;
        
            if (ft.properties[CircleOptions.OutlineWidthPropertyName] == null)
                ft.properties[CircleOptions.OutlineWidthPropertyName] = this.outlineWidth;
            
            ft.properties[HighlightOptions.HighlightTypePropertyName] = type;
            return ft.properties;
        }
        else {
            const props = { id: Guid.create().toString() };
            props[CircleOptions.CircleColorPropertyName] = this.color;
            props[CircleOptions.CircleOpacityPropertyName] = this.opacity;
            props[CircleOptions.CircleRadiusPropertyName] = this.radius;
            props[CircleOptions.CircleRadiusUnitTypePropertyName] = CircleOptions.circleRadiusTypeToString(this.radiusUnitType);
            props[CircleOptions.OutlineColorPropertyName] = this.outlineColor;
            props[CircleOptions.OutlineWidthPropertyName] = this.outlineWidth;
            props[HighlightOptions.HighlightTypePropertyName] = type;
            return props;
        }
    }

    public static circleRadiusTypeToString(type: CircleRadiusUnitType): string {
        return CircleRadiusUnitType[type].toString().toLowerCase();
    }
}

export interface ICircleOptions extends IHighlightOptions {
    color?: string;
    opacity?: number;
    radius?: number;
    radiusUnitType?: CircleRadiusUnitType;
    outlineColor?: string;
    outlineWidth?: number;
    cameraOptions?: IPanOptions;
}

export enum CircleRadiusUnitType {
    Pixels = 0//,
    // Meters = 1
}
