import * as maplibregl from 'maplibre-gl';
import Mustache from 'mustache';

import { CoordinateLike, Geometry, LayerGroup, Map, MapEventArgs, Point, Popup, RM2Event } from '../..';
import { ContainerTemplate } from './templates/container-template';
import { RMBClickTemplate, RMBClickTemplateAd, RMBClickTemplateCesta, RMBClickTemplateInfo } from './templates/rmb-click-template';
import { SearchResultsTemplate } from './templates/search-results-template';
import { LegendTemplate } from './templates/legend-template';
import { RoutingHeaderTemplate } from './templates/routing-header-template';
import { RouteTabTemplate } from './templates/route-tab-template';
import { ErrorTemplate } from './templates/error-template';

import { IPrometSiControlOptions, PrometSiControlOptions, PrometSiRoutingLocation, PrometSiSearchResultsType } from './RM2PrometSiControlOptions';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NominatimService, Query_v2ResponseFeatureProperties, Query_v3ResponseFeatureProperties } from '../../services/RM2NominatimService';
import { ServiceType } from '../../services/RM2Service';
import { Feature } from '../../model/RM2Feature';
import { Route, RouteFeatureCollection, RouteLegacy, RoutingService } from '../../services/RM2RoutingService';
import { IPanOptions } from '../../model/RM2CameraChangeOptions';
import { Utils } from '../../services/RM2StaticService';
import { LocalizationService } from '../../services/RM2LocalizationService';
import { Guid } from 'guid-typescript';
import { RouteStatusResponseDTO } from '../../model/RouteStatus';
import { PersistedVisibility } from '../../map/RM2Map';
import { IMarkerOptions } from '../../highlights/RM2HighlightOptions';
import { FeatureCollection, Position } from '@turf/helpers';
import { Projection } from '../../model/RM2Projection';
import { PrometSiExpandMapControl } from './promet-si-expand-map-control/RM2PrometSiExpandMapControl';
import { MeteoWeatherData } from '../../model/MeteoWeatherData';
import proj4 from 'proj4';

// REQUIRE
// require('../../../demo/assets/environment-promet-si/css/main.css');
// require('../../../demo/assets/res/css/RM2MapControl.css');
// require('../../../demo/assets/res/css/RM2PrometSiControl.css');

declare var $: any;

export class PrometSiControl implements maplibregl.IControl {
    protected _map: Map;

    private _options: IPrometSiControlOptions;

    private _nominatimService: NominatimService;
    private _routingService: RoutingService;
    private _localizationService: LocalizationService;

    private _container: HTMLDivElement;
    private _searchBarContainer: HTMLDivElement;
    private _searchResults: HTMLDivElement;
    // private _legendBg: HTMLDivElement;
    private _legend: HTMLDivElement;
    private _routing: HTMLDivElement;
    private _routingBottomSearchResultsContainer: HTMLDivElement;
    private _routingHeader: HTMLDivElement;
    private _routingTabsContainer: HTMLDivElement;
    private _routingBottom: HTMLDivElement;
    private _routingSave: HTMLDivElement;
    private _routingSpinner: HTMLDivElement;
    private _routingError: HTMLDivElement;
    private _routingSearchResults: HTMLDivElement;
    private _searchInput: { element: HTMLInputElement, data?: Feature<Query_v3ResponseFeatureProperties> };

    private readonly weatherLayerName = 'mb-r-meteo-weather-layer';
    private readonly weatherSourceName = 'mb-r-meteo-weather-source';
    private _weatherGroup: string;
    private _weatherData: MeteoWeatherData[] = [];
    private _weatherContainer: HTMLDivElement;
    private _weatherTimestampSpan: HTMLSpanElement;

    private popupRmb: Popup;
    private _searchResultsFeatures: Feature<Query_v3ResponseFeatureProperties>[] = [];

    public get onRouteSaved() { return this._onRouteSaved.expose(); }
    protected readonly _onRouteSaved = new RM2Event<Route>();

    private _focusedSearchInput: PrometSiRoutingLocation;
    private get focusedSearchInput(): PrometSiRoutingLocation { return this._focusedSearchInput; }
    private set focusedSearchInput(value: PrometSiRoutingLocation) {
        this._focusedSearchInput = value;
        if (value == null)
            this.setSearchResultsStatus(false, PrometSiSearchResultsType.Routing);
    }

    private refreshRouteDebouncing: Subject<void> = new Subject<void>();
    private searchTextDebouncing: Subject<{ text: string, type: PrometSiSearchResultsType }> = new Subject<{ text: string, type: PrometSiSearchResultsType }>();
    private _routingLocationsIncrementCount: number;
    private _routingLocations: PrometSiRoutingLocation[] = this.getNewRoutingLocationsArray();

    private getNewRoutingLocationsArray(destination?: Feature<Query_v3ResponseFeatureProperties>): PrometSiRoutingLocation[] {
        this._routingLocationsIncrementCount = 3;
        const dest = new PrometSiRoutingLocation('input-2', false);
        if (destination) {
            dest.data = destination;
            const coord = destination.geometry.getCoordinate();
            dest.coords = [coord.x, coord.y];
        }

        return [
            new PrometSiRoutingLocation('input-1'),
            dest
        ];
    }

    private getRoutingLocationsArray(items: Feature<Query_v3ResponseFeatureProperties>[]): PrometSiRoutingLocation[] {
        this._routingLocationsIncrementCount = 3;
        const arr: PrometSiRoutingLocation[] = [];
        for (let i = 0; i < items.length; i++) {
            const item = new PrometSiRoutingLocation(`input-${i}`, i !== items.length - 1);
            item.data = items[i];
            const coord = items[i].geometry.getCoordinate();
            item.coords = [coord.x, coord.y];
            arr.push(item);
        }
        return arr;
    }

    private get responsiveBreakpoint(): number { return 503; }
    private get searchEntryWidth(): string { return '300px'; }
    // private get legendWidth(): string { return '430px'; }
    private get legendWidth(): string { return '300px'; }
    private get routingWidth(): string { return '430px'; }
    private get searchResultsMaxHeight(): string { return '300px'; }

    private get routeStatusKey(): string { return 'Status'; }
    private get routeIdKey(): string { return 'RouteId'; }
    private get routeTTBadgeClassKey(): string { return 'TTClass'; }

    private openLegendKey: string = 'RM2Map.PrometSi.OpenedLegend';

    constructor(map: Map, opts?: IPrometSiControlOptions) {
        this._map = map;
        this._options = new PrometSiControlOptions(opts);

        this._nominatimService = this._map.getService(ServiceType.Nominatim) as NominatimService;
        this._routingService = this._map.getService(ServiceType.Routing) as RoutingService;
        this._localizationService = this._map.getService(ServiceType.Localization) as LocalizationService;

        this._container = this.create();

        this.searchTextDebouncing.pipe(debounceTime(500)).subscribe(async (e) => this.search(e.text, e.type));
        this.refreshRouteDebouncing.pipe(debounceTime(500)).subscribe(() => this.refreshRoutes());

        this._map.onLongClick.subscribe(e => this.openMapClickOptionsMenu(e));

        this._map.onClick.subscribe(e => {
            if (e.nativeEvent instanceof MouseEvent) {
                if (e.nativeEvent.button === 2) // RMB
                    this.openMapClickOptionsMenu(e);
                else if (e.nativeEvent.button === 0) { // LMB
                    if (this.popupRmb)
                        this.popupRmb.close();
                    if (this.getRoutingStatus() === true && (this.routes == null || this.routes.length === 0)) {
                        // set coordinate as next route point
                    }
                    else {
                        const filtered = e.featureLayers.filter(l => l.source === Map.routeSourceName);
                        if (filtered.length > 0 && filtered.length == e.featureLayers.length) {
                            const ft = e.features[0];
                            const routeId = ft.properties[this.routeIdKey];
                            const selected = this.routes.findIndex(r => r[this.routeIdKey] === routeId);
                            if (selected !== this.selectedRoute)
                                this.setRoutesStatus(this.routes, selected, false);
                        }
                    }
                }
            }
        });
    }

    private openMapClickOptionsMenu(e: MapEventArgs) {
        e.nativeEvent.preventDefault();
        const template = document.createElement('div');
        template.innerHTML = Mustache.render(RMBClickTemplate, {});
        this._localizationService.refresh(template);

        const offset: [number, number] = [0, 0];
        // TODO: promises & cancellations
        const info: HTMLAnchorElement = template.querySelector('#promet-si-rmb-info');
        info.onclick = async () => {
            this._map.closeAllPopups();
            const res = await this._nominatimService.reverse_v3(e.coordinate);
            const content = document.createElement('div');
            content.className = 'p-2';
            if (res && res.features && res.features.length > 0) {
                const ft = res.first;
                // round distances
                if (ft.properties.Ad && ft.properties.Ad.dist)
                    ft.properties.Ad.dist = Math.round(ft.properties.Ad.dist);
                if (ft.properties.Cesta && ft.properties.Cesta.dist)
                    ft.properties.Cesta.dist = Math.round(ft.properties.Cesta.dist);
                if (ft.properties.Cesta && ft.properties.Cesta.ds_at)
                    ft.properties.Cesta.ds_at = Math.round(ft.properties.Cesta.ds_at);

                ft.properties.x = Math.round(ft.geometry.coordinates[0] as number * 100000) / 100000;
                ft.properties.y = Math.round(ft.geometry.coordinates[1] as number * 100000) / 100000;

                content.innerHTML = Mustache.render(RMBClickTemplateInfo, ft);
                this._localizationService.refresh(content);

                const adEl: HTMLAnchorElement = content.querySelector('#promet-si-geocoding-ad');
                if (adEl) {
                    adEl.onclick = () => {
                        this._map.closeAllPopups();
                        const adContent = document.createElement('div');
                        adContent.innerHTML = Mustache.render(RMBClickTemplateAd, ft.properties.Ad);
                        this.popupRmb = this._map.openPopup([ft.properties.Ad.bvx, ft.properties.Ad.bvy], adContent, {
                            offset: offset
                        });
                    };
                }

                const cestaEl: HTMLAnchorElement = content.querySelector('#promet-si-geocoding-cesta');
                if (cestaEl) {
                    cestaEl.onclick = () => {
                        this._map.closeAllPopups();
                        const cestaContent = document.createElement('div');
                        cestaContent.innerHTML = Mustache.render(RMBClickTemplateCesta, ft.properties.Cesta);
                        this.popupRmb = this._map.openPopup([ft.properties.Cesta.bvx, ft.properties.Cesta.bvy], cestaContent, {
                            offset: offset
                        });
                    };
                }
            }
            else {
                // TODO
            }

            this.popupRmb = this._map.openPopup(e.coordinate, content, {
                offset: offset
            });
        };

        const start: HTMLAnchorElement = template.querySelector('#promet-si-rmb-start');
        start.onclick = async () => {
            this._map.closeAllPopups();
            this.setRoutingStatus(true);
            const res = await this._nominatimService.reverse_v3(e.coordinate);
            if (res && res.features && res.features.length > 0) {
                this.focusedSearchInput = this._routingLocations[0];
                this.onSearchResultSelected(res.first, PrometSiSearchResultsType.Routing);
            }
        };

        const via: HTMLAnchorElement = template.querySelector('#promet-si-rmb-via');
        via.onclick = async () => {
            this._map.closeAllPopups();
            this.setRoutingStatus(true);
            const res = await this._nominatimService.reverse_v3(e.coordinate);
            if (res && res.features && res.features.length > 0) {
                this._routingLocations.splice(this._routingLocations.length - 1, 0, new PrometSiRoutingLocation(`input-${this._routingLocationsIncrementCount++}`));
                this.refreshRoutingLocations();
                this.refreshRoutingSize();

                this.focusedSearchInput = this._routingLocations[this._routingLocations.length - 2];
                this.onSearchResultSelected(res.first, PrometSiSearchResultsType.Routing);
            }
        };

        const destination: HTMLAnchorElement = template.querySelector('#promet-si-rmb-destination');
        destination.onclick = async () => {
            this._map.closeAllPopups();
            this.setRoutingStatus(true);
            const res = await this._nominatimService.reverse_v3(e.coordinate);
            if (res && res.features && res.features.length > 0) {
                this.focusedSearchInput = this._routingLocations[this._routingLocations.length - 1];
                this.onSearchResultSelected(res.first, PrometSiSearchResultsType.Routing);
            }
        };

        this._map.closeAllPopups();
        this.popupRmb = this._map.openPopup(e.coordinate, template, {
            addContainer: false,
            hasCloseButton: false,
            closesOnClick: false,
            panOnOpen: false,
            offset: offset
            // cameraOptions: { relCenterX: this.getRelCenterX() }
        });

        this.popupRmb.onClose.subscribe(() => this.popupRmb = null);
    }

    injectedRouteId: string = null;
    injectedRouteTitle: string = null;
    public injectRouteForEditing(route: RouteLegacy) {
        if (route) {
            this.setRoutingStatus(true);
            const points = route.Data.features.map(x => {
                const ft = Object.assign({}, x);
                ft.geometry = Geometry.transform(x.geometry, Projection.create('EPSG:3912'), Projection.create('EPSG:4326'));
                return Feature.fromGeoJson(ft);
            });

            // // MOCK PODATKI ZA TEST
            // const mockPoints = [[14.501556, 46.056458], [14.491636, 46.067675], [14.478019, 46.083590], [14.470677, 46.107190]];
            // const points = route.Data.features.map((x, i) => {
            //     const ft = Object.assign({}, x);
            //     ft.geometry.coordinates = mockPoints[i];
            //     return Feature.fromGeoJson(ft);
            // });

            this.injectedRouteId = route.Id;
            this.injectedRouteTitle = route.Title;
            this._routingLocations = this.getRoutingLocationsArray(points);
            this._searchInput.element.value = '';
            this._searchInput.data = null;
            this.setRoutesStatus([]);
            this.refreshRoutingLocations();
            // this.refreshRoute();
            this.refreshRouteDebouncing.next();
        }
    }

    public clear() {
        this.currFetchId = null;
        this.injectedRouteId = null;
        this.injectedRouteTitle = null;
        this._routingLocations = this.getNewRoutingLocationsArray();
        this._searchInput.element.value = '';
        this._searchInput.data = null;
        this.setRoutesStatus([]);
        this.refreshRoutingLocations();
        // this.refreshRoute();
        this.refreshRouteDebouncing.next();
    }

    private create(): HTMLDivElement {
        const menuContainer = document.createElement('div');
        menuContainer.className = 'promet-si-search-container animate-left rounded bg-white position-absolute';
        const containerParsed = Mustache.render(ContainerTemplate, {});
        this._localizationService.refresh(containerParsed);
        menuContainer.innerHTML = containerParsed;
        this._searchBarContainer = menuContainer;
        this.refreshSearchBarContainerSize();

        // search results
        this._searchResults = document.createElement('div');
        this._searchResults.className = 'animate-max-height animate-left shadow-sm';
        this._searchResults.style.margin = '7px -7px 0 -7px';
        this.setSearchResultsStatus(false, PrometSiSearchResultsType.Normal); // hide by default

        // // legend dim background
        // const legendBg = document.createElement('div');
        // legendBg.className = 'promet-si-legend-dim';
        // legendBg.onclick = () => this.setLegendStatus(false);
        // this._legendBg = legendBg;

        // legend
        const legend = document.createElement('div');
        legend.className = "bg position-absolute animate-left promet-si-legend";

        const legendGroups = this.getLegendGroups();
        legend.innerHTML = Mustache.render(LegendTemplate, {
            groups: legendGroups
        });
        this._localizationService.refresh(legend);

        const hideLegendButton: HTMLButtonElement = legend.querySelector('#hide-legend-button');
        hideLegendButton.onclick = () => this.setLegendStatus(false);
        this._legend = legend;
        this.refreshLegendSize();

        if (this._options.openLegend === true && localStorage && !localStorage.getItem(this.openLegendKey))
            this.setLegendStatus(true);
        else
            this.setLegendStatus(false);

        // legend item status UI
        const updateLegendItemUI = (item: HTMLInputElement, checked: boolean) => {
            item.checked = checked; // check

            // background
            if (checked)
                item.parentElement.parentElement.classList.add('bg-selected');
            else
                item.parentElement.parentElement.classList.remove('bg-selected');
        };

        // legend checkboxes
        const checkboxes = this._legend.querySelectorAll("[id^='checkbox-']");
        checkboxes.forEach((c: HTMLInputElement) => {
            updateLegendItemUI(c, (legendGroups.filter(l => l.isCheckbox === true).map(l => l.layers) as any).flat().find((g: LayerGroup) => g.id === c.name).visible !== false); // set initial UI
            c.addEventListener('input', e => {
                this._map.setGroupVisibility(c.name, c.checked);
                this._map.persistVisibility([{ type: 'group', id: c.name, visible: c.checked }]);
            }); // set visibility
        });

        // legend radios
        const radios = this._legend.querySelectorAll("[id^='radio-']");
        radios.forEach((c: HTMLInputElement) => {
            const parent: LayerGroup = (legendGroups.filter(l => l.isCheckbox === false).map(l => l.layers) as any).flat().find((g: LayerGroup) => g.id === c.name);
            updateLegendItemUI(c, parent.children.find(l => l.id === c.value).visible !== false); // set initial UI
            c.addEventListener('input', e => {
                const filtered = parent.children.filter(child => child.id !== c.value);
                filtered.forEach(other => this._map.setGroupVisibility(other.id, false));
                this._map.setGroupVisibility(c.value, true); // set visibility

                const persist: PersistedVisibility[] = filtered.map(x => {
                    return {
                        type: 'group',
                        id: x.id,
                        visible: false
                    };
                });

                persist.push({
                    type: 'group',
                    id: c.id,
                    visible: true
                });

                this._map.persistVisibility(persist);
            });
        });

        const weatherLayer = this._map.getLayer(this.weatherLayerName);
        if (weatherLayer && weatherLayer.metadata)
            this._weatherGroup = weatherLayer.metadata.gid;

        if (this._weatherGroup && this._map.getGroupVisibility(this._weatherGroup))
            this.tryStartWeatherLayerUpdating(true);

        // group visibility update
        this._map.onGroupVisibilityChanged.subscribe(g => {
            const parent = g.getParent();
            if (parent && parent.sequenceMode === 'choice') {
                // update radios
                const radios = this._legend.querySelectorAll(`[name="${parent.id}"]`);
                radios.forEach((radio: HTMLInputElement) => updateLegendItemUI(radio, radio.value === g.id));
            }
            else {
                // update checkbox
                const checkbox: HTMLInputElement = this._legend.querySelector(`[id^='checkbox-${g.id}']`);
                updateLegendItemUI(checkbox, g.visible !== false);
            }

            // listen to weather layer visibility changes
            if (this._weatherGroup && g.id === this._weatherGroup) {
                if (this._map.getGroupVisibility(g.id))
                    this.tryStartWeatherLayerUpdating();
                else
                    this.stopWeatherLayerUpdating();
            }
        });

        // menu button
        const menuButton: HTMLElement = menuContainer.querySelector('#menu-button');
        menuButton.onclick = () => {
            if (this.getSearchResultsStatus())
                this.setSearchResultsStatus(false, PrometSiSearchResultsType.Normal);
            else
                this.setLegendStatus(!this.getLegendStatus());
        };

        if (this._options.hasLegend === false)
            menuButton.parentElement.removeChild(menuButton);

        // routing button
        const routingButton: HTMLElement = menuContainer.querySelector('#routing-button');
        routingButton.onclick = () => this.setRoutingStatus(true);

        // routing
        this._routing = document.createElement('div');
        this._routing.className = "position-absolute animate-opacity promet-si-routing d-flex flex-column";
        this._routingHeader = document.createElement('div');
        this.refreshRoutingLocations();
        this._routing.appendChild(this._routingHeader);
        this._routing.style.visibility = 'hidden';

        // routing bottom
        this._routingBottom = document.createElement('div');
        // this._routingBottom.className = 'position-relative bg flex-grow-1 d-flex flex-column';
        this._routingBottom.className = 'position-relative bg h-100';
        this._routingBottom.style.overflowY = 'auto';

        // routing bottom search results
        this._routingSearchResults = document.createElement('div');
        this._routingSearchResults.className = 'animate-max-height border border-top-0 rounded-bottom shadow-sm';
        this._routingSearchResults.style.zIndex = '10';
        // this._routingSearchResults.style.left = '-31px';
        this._routingSearchResults.style.left = '0px';
        this._routingSearchResults.style.right = '0px';
        this._routingSearchResults.style.top = '0px';
        // this._routingSearchResults.style.position = 'fixed';
        this._routingSearchResults.style.position = 'absolute';
        this.setSearchResultsStatus(false, PrometSiSearchResultsType.Routing); // hide by default

        // this._routingBottom.appendChild(this._routingSearchResults);
        this._routingBottomSearchResultsContainer = document.createElement('div');
        this._routingBottomSearchResultsContainer.className = 'position-relative h-100 flex-grow-1';
        this._routingBottomSearchResultsContainer.appendChild(this._routingBottom);
        this._routingBottomSearchResultsContainer.appendChild(this._routingSearchResults);

        this._routing.appendChild(this._routingBottomSearchResultsContainer);

        // search input
        this._searchInput = { element: menuContainer.querySelector('#search-input') };
        this._searchInput.element.addEventListener('input', e => this.searchTextDebouncing.next({ text: this._searchInput.element.value, type: PrometSiSearchResultsType.Normal }));
        this._searchInput.element.addEventListener('keyup', e => this.onSearchInputEnterKey(e, PrometSiSearchResultsType.Normal));

        menuContainer.appendChild(this._searchResults);

        // weather
        const weatherContainer = document.createElement('div');
        weatherContainer.className = 'position-absolute';
        weatherContainer.style.boxShadow = 'none';
        const weatherBg = document.createElement('div');
        weatherBg.className = 'rm2-meteo-weather-bg';
        const text = document.createElement('span');
        weatherBg.appendChild(text);
        weatherContainer.appendChild(weatherBg);
        this._weatherContainer = weatherContainer;
        this._weatherTimestampSpan = text;
        this.stopWeatherLayerUpdating();

        window.addEventListener('resize', this.resizeElements);
        this.resizeElements();

        const container = document.createElement('div');
        container.appendChild(menuContainer);
        container.appendChild(weatherContainer);
        container.appendChild(this._routing);
        // container.appendChild(legendBg);
        container.appendChild(this._legend);
        return container;
    }

    private setSearchResults(results: Feature<Query_v3ResponseFeatureProperties>[], type: PrometSiSearchResultsType) {
        results.forEach((ft: any, i) => {
            ft.index = i; // indeksiranje za Mustache
            if (!ft.properties.Description)
                ft.properties.Description = ft.properties.Title;
        });
        this._searchResultsFeatures = results;
        const searchResultsParsed = Mustache.render(SearchResultsTemplate, {
            results: results
        });

        const container = this.getContainerFromSearchResultsType(type);
        container.innerHTML = searchResultsParsed;

        // search result select listener
        const items = container.querySelectorAll('a');
        items.forEach(item => item.onclick = () => {
            const i = Number.parseInt(item.id.substring(7));
            const result = results[i];
            this.onSearchResultSelected(result, type);
            this.setSearchResultsStatus(false, type);
        });

        this.setSearchResultsStatus(results.length > 0, type);
    }

    private onSearchInputEnterKey(e: KeyboardEvent, type: PrometSiSearchResultsType) {
        e.preventDefault();
        if (e.code === 'Enter' && this._searchResultsFeatures.length === 1) {
            this.onSearchResultSelected(this._searchResultsFeatures[0], type);
            this.setSearchResultsStatus(false, type);
        }
    }

    private setSearchResultsStatus(visible: boolean, type: PrometSiSearchResultsType) {
        this.getContainerFromSearchResultsType(type).style.maxHeight = visible ? this.searchResultsMaxHeight : '0px';
        if (visible === false)
            this._searchResultsFeatures = [];
    }

    private getSearchResultsStatus(): boolean {
        return this._searchResults.style.maxHeight !== '0px';
    }

    private onSearchResultSelected(result: Feature<Query_v3ResponseFeatureProperties>, type: PrometSiSearchResultsType) {
        if (type === PrometSiSearchResultsType.Normal) {
            this._searchInput.element.value = result.properties.Title;
            this._searchInput.data = result;
            const coord = result.geometry.getCoordinate();
            this.highlightLocation([coord.x, coord.y]);
        }
        else if (type === PrometSiSearchResultsType.Routing) {
            const coord = result.geometry.getCoordinate();
            this.focusedSearchInput.element.value = result.properties.Title;
            this.focusedSearchInput.coords = [coord.x, coord.y];
            this.focusedSearchInput.data = result;
            // this.refreshRoute();
            this.refreshRouteDebouncing.next();
        }
    }

    private async search(query: string, type: PrometSiSearchResultsType) {
        if (query.length > 0) {
            const locs = await this._nominatimService.query_v3(query);
            const fts = locs.features.slice();
            this.setSearchResults(fts, type);
        }
        else
            this.setSearchResultsStatus(false, type);
    }

    private getLegendStatus(): boolean {
        return this._legend.getAttribute('visible') === 'true';
    }

    private setLegendStatus(visible: boolean) {
        const width = this.getLegendWidth();
        const l = visible ? '-10px' : `calc(-${width} - 10px)`;
        const lPositive = visible ? `${width}` : '10px';
        this._legend.style.left = l;
        this._legend.setAttribute('visible', visible.toString());
        this._searchBarContainer.style.left = lPositive;

        // // this._searchResults.style.left = lPositive;
        // if (visible)
        //     this._legendBg.classList.add('show');
        // else
        //     this._legendBg.classList.remove('show');

        if (this._options.openLegend && visible === false) {
            if (localStorage) {
                localStorage.setItem(this.openLegendKey, 'true');
            }
        }
    }

    private getLegendGroups(): { layers: LayerGroup[], isCheckbox: boolean }[] {
        const metadata = this._map.metadata;
        let groupsArray: any[] = [];
        if (metadata && metadata.groups) {
            const groups = metadata.groups.filter(g => g.id && g.uiVisible !== false);
            const radio = groups.filter(g => g.sequenceMode === 'choice');
            const checkbox = groups.filter(g => g.sequenceMode !== 'choice');

            groupsArray = [
                {
                    layers: checkbox,
                    isCheckbox: true
                },
                {
                    layers: radio,
                    isCheckbox: false
                }
            ]
        }

        return groupsArray;
    }

    private setRoutingStatus(visible: boolean) {
        this.focusedSearchInput = null;
        this._map.clearRoute();
        if (visible) {
            this._routing.classList.add('show');
            if (this._searchInput.data == undefined) {
                if (this.routes.length > 0) {
                    this.setRoutesStatus(this.routes, this.selectedRoute); // show previous route
                    this.startRouteStatusRefresh(this.currStatusRefreshId, this.routes, false);
                }
                else if (this._routingLocations.length > 0) {
                    // show selected marker
                    const waypoints = this._routingLocations.filter(l => l.coords != null).map(l => l.coords);
                    if (waypoints.length > 0)
                        this.highlightLocation(waypoints[0]);
                }
            }
            this.setLegendStatus(false);
            this.setSearchResultsStatus(false, PrometSiSearchResultsType.Normal);
            this.setLegendStatus(false);
        }
        else {
            this._routing.classList.remove('show');
            this.clearHighlight();
            this.clearInstructionHighlight();
            this.stopRouteStatusRefresh();
            this.stopGeolocationSearch();
        }

        if (this._searchInput.data) {
            this._routingLocations = this.getNewRoutingLocationsArray(this._searchInput.data);
            this._searchInput.element.value = '';
            this._searchInput.data = null;
            this.setRoutesStatus([]);
            this.refreshRoutingLocations();
            // this.refreshRoute();
            this.refreshRouteDebouncing.next();

            // focus on first input
            const firstInput: HTMLInputElement = this._routingHeader.querySelector("input");
            firstInput.focus();
        }

        if (visible)
            this.refreshRoutingSize();
    }

    private getRoutingStatus(): boolean {
        return this._routing.classList.contains('show');
    }

    private myLocExecuting: number = null;
    private refreshRoutingLocations() {
        for (let i = 0; i < this._routingLocations.length; i++) {
            const loc = this._routingLocations[i];
            const isLast = i === this._routingLocations.length - 1;
            if (i === 0)
                loc.icon = 'assets/icons/routing_marker_start.svg';
            else if (isLast)
                loc.icon = 'assets/icons/routing_marker_end.svg';
            else
                loc.icon = 'assets/icons/routing_marker_via.svg';

            loc.canSwapWithNext = isLast === false;
        }

        this._routingHeader.innerHTML = Mustache.render(RoutingHeaderTemplate, {
            locations: this._routingLocations
        });
        this._localizationService.refresh(this._routingHeader);

        // clear input button
        const myLocButtons = this._routingHeader.querySelectorAll("[id^='my-loc-']");
        myLocButtons.forEach((btn: HTMLDivElement) => {
            btn.onclick = async () => {
                this.setRoutingSpinnerStatus(true);
                this.myLocExecuting = navigator.geolocation.watchPosition(async (e) => {
                    const locs = await this._nominatimService.reverse_v3([e.coords.longitude, e.coords.latitude]);
                    if (locs && locs.length > 0) {
                        const result = locs.features[0];
                        const coord = result.geometry.getCoordinate();
                        this.focusedSearchInput = this._routingLocations.find(l => l.id === btn.id.substr(7));
                        this.focusedSearchInput.element.value = result.properties.Title;
                        this.focusedSearchInput.coords = [coord.x, coord.y];
                        this.focusedSearchInput.data = result;
                    }
                    else
                        this.setRoutingErrorStatus(this._localizationService.localize('rmap.general.errors.cannot-get-user-location'));

                    this.setRoutingSpinnerStatus(false);
                    this.refreshRoutingLocations();

                    // this.refreshRoute();
                    this.refreshRouteDebouncing.next();
                    this.stopGeolocationSearch();
                },
                    () => {
                        this.setRoutingErrorStatus(this._localizationService.localize('rmap.general.errors.cannot-get-user-location'));
                        this.setRoutingSpinnerStatus(false);
                        this.stopGeolocationSearch();
                    });


                // const found = this._routingLocations.findIndex(l => l.id === btn.id.substr(7));
                // const loc = this._routingLocations[found];
                // // remove input
                // const before = this._routingLocations.length;
                // if (this._routingLocations.length > 2)
                //     this._routingLocations.splice(found, 1);
                // else {
                //     if (loc.data || loc.element.value) {
                //         // clear input
                //         loc.data = null;
                //         loc.coords = null;
                //         loc.element.value = '';
                //     }
                // }

                // this.refreshRoutingLocations();
                // if (before != this._routingLocations.length)
                //     this.refreshRoutingSize();

                // // this.refreshRoute();
                // this.refreshRouteDebouncing.next();
            };
        });

        // clear input button
        const clearInputButtons = this._routingHeader.querySelectorAll("[id^='remove-']");
        clearInputButtons.forEach((btn: HTMLDivElement) => {
            btn.onclick = () => {
                const found = this._routingLocations.findIndex(l => l.id === btn.id.substr(7));
                const loc = this._routingLocations[found];
                // remove input
                const before = this._routingLocations.length;
                if (this._routingLocations.length > 2)
                    this._routingLocations.splice(found, 1);
                else {
                    if (loc.data || loc.element.value) {
                        // clear input
                        loc.data = null;
                        loc.coords = null;
                        loc.element.value = '';
                    }
                }

                this.refreshRoutingLocations();
                if (before != this._routingLocations.length)
                    this.refreshRoutingSize();

                // this.refreshRoute();
                this.refreshRouteDebouncing.next();
            };
        });

        // swap locations button
        const swapButtons = this._routingHeader.querySelectorAll("[id^='swap-']");
        swapButtons.forEach((btn: HTMLButtonElement) => {
            btn.onclick = () => {
                const found = this._routingLocations.findIndex(l => l.id === btn.name);
                [this._routingLocations[found], this._routingLocations[found + 1]] = [this._routingLocations[found + 1], this._routingLocations[found]]
                this.refreshRoutingLocations();
                // this.refreshRoute();
                this.refreshRouteDebouncing.next();
            };
        });

        // inputs
        const inputs = this._routingHeader.querySelectorAll("[id^='input-']");
        inputs.forEach((input: HTMLInputElement) => {
            const loc = this._routingLocations.find(l => l.id === input.name);
            loc.element = input;
            if (loc.data)
                input.value = loc.data.properties.Title; // initial value

            input.addEventListener('input', e => this.searchTextDebouncing.next({ text: input.value, type: PrometSiSearchResultsType.Routing }));
            input.addEventListener('focus', e => this.focusedSearchInput = loc);
            input.addEventListener('keydown', e => {
                if (e.code === 'Enter')
                    e.preventDefault();
            });
            input.addEventListener('keyup', e => this.onSearchInputEnterKey(e, PrometSiSearchResultsType.Routing));
        });

        // routing hide button
        const routingHideButton: HTMLDivElement = this._routingHeader.querySelector('#hide-button');
        routingHideButton.onclick = () => this.setRoutingStatus(false);

        // add location button
        const addLocationButton: HTMLButtonElement = this._routingHeader.querySelector('#add-location-button');
        addLocationButton.onclick = () => {
            this._routingLocations.splice(this._routingLocations.length - 1, 0, new PrometSiRoutingLocation(`input-${this._routingLocationsIncrementCount++}`));
            this.refreshRoutingLocations();
            this.refreshRoutingSize();
        };
    }

    private drawRoutes(routes: Route<RouteFeatureCollection>[], pan: boolean = true) {
        const p = 30;
        for (let i = 0; i < routes.length; i++) {
            const r = routes[i];
            const s: RouteStatusResponseDTO = r[this.routeStatusKey];
            const selected = i === this.selectedRoute;
            const routeId = Guid.create().toString();
            r[this.routeIdKey] = routeId;
            if (s) {
                r.routeSegments = s.apply(r.route, selected, this._map.metadata.routeFcdColors);
                const loop = (fc: FeatureCollection) => {
                    for (let j = 0; j < fc.features.length; j++) {
                        const props = fc.features[j].properties
                        props[this.routeIdKey] = routeId;
                        props['includeInPopup'] = false;
                    }
                };

                loop(r.route);
                loop(r.routeSegments);
            }
            else {
                for (let j = 0; j < r.route.features.length; j++) {
                    const props = r.route.features[j].properties;
                    props['Color'] = Utils.routeColor(selected);
                    props[this.routeIdKey] = routeId;
                    props['includeInPopup'] = false;
                }
            }
        }

        if (this.getRoutingStatus()) {
            const reorder = routes.slice();
            if (reorder.length > 1) {
                // reorder - selected route on top
                const sel = reorder.splice(this.selectedRoute, 1);
                reorder.push(...sel);
            }

            const padding = {
                left: p,
                top: p,
                right: p,
                bottom: p
            };

            const width = this._map.getSize()[0];
            if (width > this.responsiveBreakpoint)
                padding.left = Number.parseInt(this.routingWidth.substr(0, this.routingWidth.length - 2)) + p;
            else {
                // TODO: RESPONSIVE
                padding.top = p;
                padding.bottom = p;
            }

            this._map.drawRoutes(reorder, {
                pan: pan,
                cameraOptions: {
                    padding: padding
                }
            });
        }
    }

    private routes: Route<RouteFeatureCollection>[] = [];
    private selectedRoute: number = -1;
    private setRoutesStatus(routes: Route<RouteFeatureCollection>[], selectedIndex: number = 0, pan: boolean = true) {
        this.clearRoutingTabContainer();
        this.clearInstructionHighlight();

        this.routes = routes;
        this.selectedRoute = -1;
        if (routes.length > 0) {
            this.selectedRoute = selectedIndex;
            this._routingTabsContainer = document.createElement('div');
            routes.forEach((r, i) => {
                const id = i;
                // create tab for each route
                const tab = document.createElement('div');
                const waypoints = this._routingLocations.filter(r => r.data);
                const instructions = r.route.properties.Instructions.features.slice().filter(x => x.properties.Type !== 'station-toll');
                let viaPoints = 1;
                instructions.forEach(ins => {
                    const type = ins.properties.Type;
                    const getIcon = (stationInstructionType: string) => {
                        if (stationInstructionType === 'instruction-start')
                            return 'nav_start';
                        if (stationInstructionType === 'instruction-turn-left')
                            return 'nav_left';
                        else if (stationInstructionType === 'instruction-turn-right')
                            return 'nav_right';
                        else if (stationInstructionType === 'instruction-turn-straight')
                            return 'nav_straight';
                        // // else if (stationInstructionType === 'station-toll')
                        // //     return 'fa fa-exclamation-triangle text-warning';
                        else if (stationInstructionType === 'instruction-turn-sharpleft')
                            return 'nav_sharp_left';
                        else if (stationInstructionType === 'instruction-turn-sharpright')
                            return 'nav_sharp_right';
                        else if (stationInstructionType === 'instruction-turn-uturn')
                            return 'nav_u_turn';
                        else if (stationInstructionType === 'instruction-turn-end' || stationInstructionType === 'instruction-end')
                            return 'nav_finish';
                        return 'avto_black';
                    };

                    ins.setField('CumulativeDurationString', Utils.formatDuration(ins.properties.CumulativeDuration));
                    ins.setField('CumulativeLengthString', Utils.formatDistance(ins.properties.CumulativeLength));
                    ins.setField('Icon', getIcon(type));
                    ins.setField('TitleString', ins.properties.TitleTranslations.find(t => t.Item1 == "sl").Item2); // TODO: lokalizacija

                    const coord = ins.geometry.getCoordinate();
                    ins.setField('Coordinate', `${coord.x};${coord.y}`);

                    if (type === 'instruction-via') {
                        ins.setField('TitleString', waypoints[viaPoints].data.properties.Title);
                        ins.setField('IsVia', true);
                        viaPoints++;
                    }
                });

                const first = waypoints[0].data;
                const last = waypoints[waypoints.length - 1].data;
                let detourText = '';
                if (r.route.properties.IsDetour)
                    detourText += ` (${this._localizationService.localize('rmap.rm.routing.detour').toLowerCase()})`;

                tab.innerHTML = Mustache.render(RouteTabTemplate, {
                    route: r,
                    Selected: id == selectedIndex,
                    Id: id,
                    Start: first,
                    Instructions: instructions,
                    Destination: last,
                    Length: Utils.formatDistance(r.route.properties.Length),
                    Profile: r.route.properties.Mode,
                    DetourText: detourText,
                    Duration: Utils.formatDuration(r.route.properties.RealTTMs / 1000),
                    TTClass: r[this.routeTTBadgeClassKey] || 'badge-primary d-none',
                    TTSpinnerClass: r[this.routeTTBadgeClassKey] ? 'd-none' : ''
                });

                const instrAttrName = 'data-promet-routing-instruction-coord';
                const tabClicks = tab.querySelectorAll(`[${instrAttrName}]`);
                tabClicks.forEach((click: HTMLElement) => {
                    click.onclick = () => {
                        const isntrCoord = click.getAttribute(instrAttrName);
                        const coords = isntrCoord.split(';').map(x => Number.parseFloat(x));
                        this.highlightInstruction([coords[0], coords[1]]);
                    };
                });

                this._routingTabsContainer.appendChild(tab);

                // select
                tab.onclick = () => {
                    if (selectedIndex !== id)
                        this.setRoutesStatus(routes, id);
                };
            });

            this._localizationService.refresh(this._routingTabsContainer);
            this.drawRoutes(routes, pan);
            this._routingBottom.appendChild(this._routingTabsContainer);

            // save route button
            const text = this._localizationService.localize('rmap.rm.routing.save');
            this._routingSave = document.createElement('div');
            this._routingSave.className = 'd-flex flex-column align-items-end p-4';
            const saveBtn = document.createElement('a');
            saveBtn.className = 'btn btn-primary';
            saveBtn.innerText = text;
            saveBtn.onclick = async () => {
                const isLoading = JSON.parse(saveBtn.getAttribute('data-loading'));
                if (isLoading != true) {
                    saveBtn.setAttribute('data-loading', 'true');
                    saveBtn.innerText = 'Loading ...';

                    try {
                        const r = routes[selectedIndex];
                        const route = await this._routingService.transformRoute(r, this.injectedRouteTitle);
                        // route.pointsFt = route.pointsFt.map(x => {
                        //     const ft = x.clone();
                        //     ft.geometry = Geometry.transform(ft.geometry, Projection.create('EPSG:4326'), Projection.create('EPSG:3912'));
                        //     return x.toGeoJson();
                        // });

                        this._onRouteSaved.trigger(route);
                        // saveBtn.innerText = text;
                    }
                    catch (e) {
                        console.error(e);
                        this._onRouteSaved.trigger(null);
                    }
                    finally {
                        saveBtn.setAttribute('data-loading', 'false');
                        saveBtn.innerText = text;
                    }
                }
            };
            this._routingSave.appendChild(saveBtn);
            this._routingBottom.appendChild(this._routingSave);

            // details
            routes.forEach((r, i) => {
                // TODO: unsubscribe? ali se vsi eventi sami unsubscribe-ajo?
                const id = i;
                const getOtherTabs = (): HTMLElement[] => {
                    const other = [];
                    this._routingBottom.querySelectorAll("[id^='route-tab-container-']").forEach(item => {
                        if (item.id !== `route-tab-container-${id}`)
                            other.push(item);
                    });

                    return other;
                };

                const detailsSelector = `#details-${id}`;
                $(detailsSelector).on('show.bs.collapse', () => {
                    getOtherTabs().forEach(o => o.classList.add('d-none'));
                });

                $(detailsSelector).on('hide.bs.collapse', () => {
                    getOtherTabs().forEach(o => o.classList.remove('d-none'));
                });
            });
        }
    }

    private clearRoutingTabContainer() {
        // clear tab
        if (this._routingTabsContainer) {
            this._routingBottom.removeChild(this._routingTabsContainer);
            this._routingTabsContainer = null;
        }

        if (this._routingSave) {
            this._routingBottom.removeChild(this._routingSave);
            this._routingSave = null;
        }
    }

    private setRoutingErrorStatus(status: string) {
        if (this._routingError) {
            this._routingError.parentElement.removeChild(this._routingError);
            this._routingError = null;
        }

        if (status) {
            this._routingError = document.createElement('div');
            this._routingError.className = 'p-3';
            this._routingError.innerHTML = Mustache.render(ErrorTemplate, {
                text: status
            });

            this._routingBottom.appendChild(this._routingError);
        }
    }

    private currFetchId: string;
    private currStatusRefreshId: string;
    private async refreshRoutes() {
        const locs = this._routingLocations.filter(l => l.coords != null);
        const waypoints = locs.map(l => l.coords);
        if (this.routes.length > 0) {
            const points = this.routes[0].points;
            if (points.length === waypoints.length) {
                let changed = false;
                for (let i = 0; i < points.length; i++) {
                    const p1 = points[i];
                    const p2 = waypoints[i];
                    const threshold = 0.00001;
                    if (Math.abs(p1[0] - p2[0]) > threshold && Math.abs(p1[1] - p2[1]) > threshold) {
                        changed = true;
                        break; // route changed
                    }
                }

                if (changed === false)
                    return; // route not changed
            }
        }

        // TODO: cancel previous request
        this.stopRouteStatusRefresh();
        this.currStatusRefreshId = Guid.create().toString();
        this.setRoutingErrorStatus(null);
        this.clearHighlight();
        this.clearInstructionHighlight();;
        this.clearRoutingTabContainer();
        if (waypoints.length === 1) {
            // display location
            this._map.clearRoute();
            this.highlightLocation(waypoints[0]);
            this.routes = [];
        }
        else if (waypoints.length > 1) {
            // display route
            this.setRoutingSpinnerStatus(true);

            const waypointsFts = locs.map(x => {
                const data = x.data.clone();
                const point = data.geometry.getCoordinate();
                data.geometry.coordinates = proj4('EPSG:4326', 'EPSG:3912', [point.x, point.y]);
                return data;
            });
            let results: Route<RouteFeatureCollection>[] = [];

            try {
                const fetchId = Guid.create().toString();
                this.currFetchId = fetchId;
                const res = await this._routingService.routeMultiple(waypoints);

                // cancel, če je vmes šel po drugo routeo (TODO: nekako cancel promise)
                if (this.currFetchId !== fetchId)
                    return;

                for (let i = 0; i < res.Routes.length; i++)
                    res.Routes[i].pointsFt = waypointsFts.slice();

                if (this.injectedRouteId)
                    res.Routes.forEach(r => r.route.properties.Id = this.injectedRouteId);

                results = res.Routes;
            }
            catch (e) {
                console.error('Failed to refresh routes.', e);
            }

            this.setRoutingSpinnerStatus(false);
            this.setRoutesStatus(results);

            if (results.length === 0)
                this.setRoutingErrorStatus(this._localizationService.localize('rmap.rm.routing.errors.cannot-get-route'));
            else
                this.startRouteStatusRefresh(this.currStatusRefreshId, results);
        }
    }

    private setRoutingSpinnerStatus(visible: boolean) {
        if (visible === false && this._routingSpinner) {
            this._routingSpinner.parentElement.removeChild(this._routingSpinner)
            this._routingSpinner = null;
        }
        else if (visible === true && this._routingSpinner == null) {
            const container = document.createElement('div');
            container.className = 'd-flex';

            const spinner = document.createElement('div');
            spinner.className = 'spinner-border text-primary mx-auto mt-4';
            const span = document.createElement('span');
            span.className = 'sr-only';
            span.innerText = 'Loading...';
            spinner.appendChild(span);
            container.appendChild(spinner);

            this._routingSpinner = container;
            this._routingBottom.appendChild(this._routingSpinner);
        }
    }

    private instructionHighlight: Feature;
    private highlightInstruction(coord: CoordinateLike) {
        this.clearInstructionHighlight();

        const zoom = this._map.getView().zoom;
        const defaultZoom = 14;
        this.instructionHighlight = this._map.addFeature(new Feature({
            radius: 7,
            color: Utils.routeColor(true)
        }, Point.fromCoordinate(coord)), {
            pan: true,
            cameraOptions: {
                zoom: zoom > defaultZoom ? zoom : defaultZoom,
                relCenterX: this.getRelCenterX()
            } as IPanOptions
        });

        // if (pan) {
        //     const zoom = this._map.getView().zoom;
        //     const defaultZoom = 14;
        //     this._map.pan(coord, {
        //         zoom: zoom > defaultZoom ? zoom : defaultZoom,
        //         relCenterX: this.getRelCenterX()
        //     });
        // }
    }

    private clearInstructionHighlight() {
        if (this.instructionHighlight)
            this._map.removeFeature(this.instructionHighlight.properties.id);
    }

    private highlightLocation(location: CoordinateLike) {
        const zoom = this._map.getView().zoom < 12 ? 12 : null;
        this.clearHighlight();

        this._map.drawMarker(Point.fromCoordinate(location), {
            icon: 'marker_E2001A-32',
            cameraOptions: {
                zoom: zoom,
                relCenterX: this.getRelCenterX()
            } as IPanOptions
        } as IMarkerOptions);
    }

    private clearHighlight() {
        this._map.clearMarkers();
    }

    private stopGeolocationSearch() {
        if (this.myLocExecuting) {
            navigator.geolocation.clearWatch(this.myLocExecuting);
            this.setRoutingSpinnerStatus(false);
        }
        this.myLocExecuting = null;
    }

    private getRelCenterX(): number {
        let relCenterX = 0.5;
        if (this.getRoutingStatus()) {
            // left padding
            const mapWidth = this._map.getSize()[0];
            if (mapWidth > this.responsiveBreakpoint)
                relCenterX += (Number.parseInt(this.routingWidth.substr(0, this.routingWidth.length - 2)) / mapWidth) / 2;
            else {
                // TODO: RESPONSIVE
            }
        }

        return relCenterX;
    }

    private routeStatusRefreshInterval;
    private startRouteStatusRefresh(refreshId: string, routes: Route<RouteFeatureCollection>[], refreshOnStart: boolean = true) {
        this.stopRouteStatusRefresh();

        if (refreshOnStart)
            this.routeStatusRefresh(refreshId, routes);

        this.routeStatusRefreshInterval = setInterval(() => this.routeStatusRefresh(refreshId, routes), 60000);
    }

    private stopRouteStatusRefresh() {
        if (this.routeStatusRefreshInterval)
            clearInterval(this.routeStatusRefreshInterval);
    }

    private async routeStatusRefresh(refreshId: string, routes: Route<RouteFeatureCollection>[]) {
        try {
            const statusPromises = routes.map(r => this._routingService.routeStatus(r.route));
            const status = await Promise.all(statusPromises);

            // check if not cancelled
            if (refreshId === this.currStatusRefreshId) {
                for (let i = 0; i < status.length; i++) {
                    let s = status[i];
                    const r = routes[i];
                    const ttBadge: HTMLDivElement = this._routingTabsContainer.querySelector(`#route-tab-tt-badge-${i}`);

                    if (s == undefined) {
                        // set default status
                        s = RouteStatusResponseDTO.fromRoute(r);
                    }

                    r[this.routeStatusKey] = s;

                    // set travel time badge classes
                    const cl = s.getRouteTravelTimeBackgroundClass();
                    r[this.routeTTBadgeClassKey] = cl;
                    ttBadge.classList.add(cl);
                    ttBadge.classList.remove('badge-primary', 'd-none');

                    // hide travel time spinner
                    const ttSpinner: HTMLDivElement = this._routingTabsContainer.querySelector(`#route-tab-tt-spinner-${i}`);
                    ttSpinner.classList.add('d-none');
                }

                // redraw routes
                if (this.getRoutingStatus())
                    this.drawRoutes(routes, false);
            }
        }
        catch (e) {
            console.error('Failed to update routes status.', e);

            // TODO: neko opozorilo, da ni OK
        }
    }

    private resizeElements = () => {
        const size = this._map.getSize();
        const width = size[0];
        const height = size[1];
        this._legend.style.height = `${height}px`;
        this._routing.style.height = width > this.responsiveBreakpoint ? `${height}px` : 'auto';
        this._weatherContainer.style.height = `${height}px`;

        this.refreshLegendSize();
        this.refreshRoutingSize();
        this.refreshSearchBarContainerSize();
    };

    private refreshLegendSize() {
        const size = this._map.getSize();
        const h = size[1];
        const w = size[0];
        const legendBody: HTMLElement = this._legend.querySelector('#legend-body');
        if (legendBody)
            legendBody.style.maxHeight = `${h - 60}px`;

        this._legend.style.width = this.getLegendWidth();
        // this._legendBg.style.width = `${w}px`;
        // this._legendBg.style.height = `${h}px`;

        if (this.getLegendStatus() === false) // if legend closed
            this._legend.style.left = `calc(-${this.getLegendWidth()} - 10px)`;
    }

    private refreshRoutingSize() {
        const size = this._map.getSize();
        const height = size[1];

        const h = `${height - this._routingHeader.clientHeight}px`;
        // this._routingBottom.style.maxHeight = `${height - this._routingHeader.clientHeight}px`;
        this._routingBottomSearchResultsContainer.style.maxHeight = h;
        this._routingBottom.style.maxHeight = h;
        this._routing.style.width = this.getRoutingWidth();
    }

    private refreshSearchBarContainerSize() {
        const width = this._map.getSize()[0];
        if (this._searchBarContainer)
            this._searchBarContainer.style.width = width > this.responsiveBreakpoint ? this.searchEntryWidth : `${width - 2 * 30}px`; // <503 -> tablet
    }

    private getRoutingWidth(): string {
        const width = this._map.getSize()[0];
        return width > this.responsiveBreakpoint ? this.routingWidth : `${width}px`;
    }

    private getLegendWidth(): string {
        const width = this._map.getSize()[0];
        return width > this.responsiveBreakpoint ? this.legendWidth : `${width - 85}px`;
    }

    /** WEATHER */
    private fetchWeatherData(): Promise<MeteoWeatherData[]> {
        return new Promise<MeteoWeatherData[]>((res, rej) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = 5000;
            xhr.onload = () => {
                if (xhr.responseText)
                    this._weatherData = JSON.parse(xhr.responseText);
                res(this._weatherData);
            };

            xhr.open('GET', this._options.weatherDataUrl, true);
            xhr.send(null);
        });
    }

    private tryStartWeatherLayerUpdating(onlyShowFinalFrame: boolean = false) {
        if (onlyShowFinalFrame)
            this.updateLastWeatherFrame();
        else {
            if (this._weatherData && this._weatherData.length > 0)
                this.startWeatherLayerUpdating(this._weatherData);
            else {
                this.fetchWeatherData()
                    .then(res => {
                        if (res && res.length > 0/* && this._map.getGroupVisibility(g.id)*/)
                            this.startWeatherLayerUpdating(this._weatherData);
                    });
            }
        }
    }

    private updateLastWeatherFrame() {
        this.fetchWeatherData()
            .then(res => {
                if (this._weatherAnimationTimeout)
                    clearTimeout(this._weatherAnimationTimeout);

                if (res && res.length > 0)
                    this.setWeatherSource(res[res.length - 1]);
                this._map.setLayerVisibility(this.weatherLayerName, true);
                this._weatherAnimationTimeout = setTimeout(() => this.updateLastWeatherFrame(), this._options.weatherLastFrameUpdateInterval);
            });
    }

    private setWeatherSource(el: MeteoWeatherData) {
        const path = el.path.substring(el.path.lastIndexOf('/') + 1);
        const bbox = el.bbox.split(',').map(x => Number.parseFloat(x)); // lat S, lng W, lat N, lng E
        const ws: [number, number] = [bbox[1], bbox[0]]; // lng, lat
        const en: [number, number] = [bbox[3], bbox[2]]; // lng, lat
        const srcUrl = `${this._options.weatherImgUrl}/${path}`;
        const srcBbox: [[number, number], [number, number], [number, number], [number, number]] = [
            [ws[0], en[1]], // WN (lng, lat)
            en, // EN (lng, lat)
            [en[0], ws[1]], // ES (lng, lat)
            ws // WS (lng, lat)
        ];

        const src = this._map.getSource(this.weatherSourceName) as maplibregl.ImageSource;
        if (src == undefined)
            throw new Error(`Cannot find weather source "${this.weatherSourceName}".`);
        else {
            // nastavi image url in koordinate
            src.updateImage({
                url: srcUrl,
                coordinates: srcBbox
            });
        }
    }

    private _weatherAnimationTimeout;
    private startWeatherLayerUpdating(weather: MeteoWeatherData[], from?: Date, to?: Date) {
        if (this._weatherAnimationTimeout)
            clearTimeout(this._weatherAnimationTimeout);

        // filtriraj po času
        const data = weather.filter(w => {
            const date = new Date(w.valid);
            if (from && date < from)
                return false;
            if (to && date > to)
                return false;
            return true;
        });

        const show = (i: number) => {
            const el = data[i];
            this.setWeatherSource(el);

            // začni nov timeout, da se prikaže naslednja slika
            if (this._weatherAnimationTimeout)
                clearTimeout(this._weatherAnimationTimeout);

            if (i < data.length - 1) {
                const date = new Date(el.valid);
                let h = date.getHours().toString();
                if (h.length === 1)
                    h = '0' + h;
                let min = date.getMinutes().toString();
                if (min.length === 1)
                    min = '0' + min;

                this._weatherTimestampSpan.innerText = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${h}:${min}`;

                this._weatherContainer.style.display = 'block';
                this._map.setLayerVisibility(this.weatherLayerName, true);

                this._weatherAnimationTimeout = setTimeout(() => show(i + 1), this._options.weatherFrameInterval);
            }
            else {
                this._weatherContainer.style.display = 'none';
                this._weatherAnimationTimeout = setTimeout(() => this.updateLastWeatherFrame(), this._options.weatherLastFrameUpdateInterval);
            }
        };

        show(0);
    }

    stopWeatherLayerUpdating() {
        if (this._weatherAnimationTimeout)
            clearTimeout(this._weatherAnimationTimeout);
        this._weatherContainer.style.display = 'none';
        this._map.setLayerVisibility(this.weatherLayerName, false);
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        if (this._options.hasDetailButton === true)
            map.addControl(new PrometSiExpandMapControl(this._map), 'bottom-right');

        const wrapper = document.createElement('div');
        wrapper.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        wrapper.appendChild(this._container);

        return wrapper;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        // this._panel.parentNode.removeChild(this._panel);
        // this._map = undefined;
    }

    getDefaultPosition(): maplibregl.ControlPosition {
        return 'top-left';
    }

    private getContainerFromSearchResultsType(type: PrometSiSearchResultsType): HTMLElement {
        return type === PrometSiSearchResultsType.Routing ? this._routingSearchResults : this._searchResults;
    }
}
