import * as turf_helpers from '@turf/helpers';
import * as turf_line_chunk from '@turf/line-chunk';

import { Guid } from 'guid-typescript';
import { Feature, FeatureCollection, Geometry } from '..';
import { Route, RouteFeatureCollection } from '../services/RM2RoutingService';
import { Utils } from '../services/RM2StaticService';

export interface RouteStatusRequestDTO {
    Cache: boolean;
    CurrentSegmentIndex: number;
    Route: RouteFeatureCollection;
}

export class RouteStatusResponseDTO {
    RouteId: string;
    OptimalTTMs: number;
    RealTTMs: number;
    OptimalToEndTTMs: number;
    RealToEndTTMs: number;
    TravelTimeStatus: 'normal' | 'warning' | 'critical';
    SegmentTravelTimes: number[];

    _routeColsData: any[];

    public static fromJSON(json: string): RouteStatusResponseDTO {
        const obj = JSON.parse(json);
        const dto = new RouteStatusResponseDTO();
        for (let key in obj)
            dto[key] = obj[key];
        return dto;
    }

    public static fromRoute(route: Route<RouteFeatureCollection>): RouteStatusResponseDTO {
        const r = route.route;
        const dto = new RouteStatusResponseDTO();
        dto.RouteId = Guid.create().toString();
        dto.OptimalTTMs = r.properties.OptimalTTMs;
        dto.RealTTMs = r.properties.RealTTMs;
        dto.OptimalToEndTTMs = dto.OptimalTTMs;
        dto.RealToEndTTMs = dto.RealTTMs;
        dto.TravelTimeStatus = 'normal';
        dto.SegmentTravelTimes = [];

        for (let i = 0; i < r.features.length; i++) {
            const ft = r.features[i];
            dto.SegmentTravelTimes.push(...ft.properties.SegmentIds.map(s => ft.properties.RealTTMs / ft.properties.SegmentIds.length));
        }

        return dto;
    }

    public apply(route: RouteFeatureCollection, isSelected: boolean, routeColsVals: any[]): FeatureCollection
    {
        const fc = new FeatureCollection();
        fc.properties = route.properties;

        // edit route colours (FCD)
        var consecutiveSegment = 0;
        var segmentCount = 0;

        for (let i = 0; i < route.features.length; i++)
        {
            const props = route.features[i].properties;
            const segmentNrs = props.SegmentIds.length;
            const ls = turf_line_chunk.default(turf_helpers.lineString(route.features[i].geometry.getCoordinates().map(x => [x.x, x.y])), props.SegmentLengthMM, { units: 'millimeters' });
            const segmentLs = ls.features.slice(0, segmentNrs);
            const segmentOptimalTT = props.OptimalTTMs / segmentNrs;
            for (let j = 0; j < segmentLs.length; j++) {
                const colValue = this.getRouteEdgeColorValue(this.SegmentTravelTimes[consecutiveSegment], segmentOptimalTT);
                const segFt = new Feature({
                    Color: this.getRouteColor(colValue, isSelected, routeColsVals)
                }, Geometry.fromGeoJson(segmentLs[j].geometry));
                fc.features.push(segFt);

                consecutiveSegment++;
            }

            // segmentCount += props.SegmentIds.length;

            // var sdiTravelTime = this.SegmentTravelTimes.slice(consecutiveSegment, consecutiveSegment + props.SegmentIds.length).reduce((total, num) => total + num);
            // var colValue = this.getRouteEdgeColorValue(sdiTravelTime, props.OptimalTTMs);
            // route.features[i].properties['Color'] = ;

            // consecutiveSegment += props.SegmentIds.length;
        }

        return fc;
    }

    public getRouteTravelTimeBackgroundClass(): string
    {
        if (this.TravelTimeStatus === 'normal')
            return 'badge-green';
        else if (this.TravelTimeStatus === 'warning')
            return 'badge-orange';
        else if (this.TravelTimeStatus === 'critical')
            return 'badge-red';
        return 'badge-primary';
    }

    public getRouteEdgeColorValue(tt: number, optimalTT: number): number
    {
        var diff = (tt / optimalTT) - 1;
        return diff > 0 ? (diff * 100) : 0;
    }

    public getRouteColor(colorValue: number, isSelected: boolean, routeColsVals: any[]): string
    {
        // get data
        const d = this.getColorVals(colorValue, routeColsVals);

        // interpolate color
        var fraction = d.upper == d.lower ? 0 : (colorValue - d.lower) / (d.upper - d.lower);
        var lowerColor = this.hexToRgb(isSelected ? d.lowerData["light"] : d.lowerData["unselected"]);
        var upperColor = this.hexToRgb(isSelected ? d.upperData["light"] : d.upperData["unselected"]);

        return this.rgbToHex(lowerColor.r + (upperColor.r - lowerColor.r) * fraction, lowerColor.g + (upperColor.g - lowerColor.g) * fraction, lowerColor.b + (upperColor.b - lowerColor.b) * fraction);
    }

    private getColorVals(colorValue: number, routeColsVals: any[]): { lower: number, lowerData: any, upper: number, upperData: any }
    {
        // lower limit for interpolation
        var lowerIndex = 0;
        for (var i = routeColsVals.length - 2; i >= 0; i -= 2)
        {
            var stop: number = routeColsVals[i];
            if (colorValue > stop)
            {
                lowerIndex = i;
                break;
            }
        }

        // upper limit for interpolation
        var aboveIndex = lowerIndex + 2 < routeColsVals.length - 1 ? lowerIndex + 2 : lowerIndex;

        // get data
        var lower: number = routeColsVals[lowerIndex];
        var lowerData = routeColsVals[lowerIndex + 1];
        var upper: number = routeColsVals[aboveIndex];
        var upperData = routeColsVals[aboveIndex + 1];

        return { lower: lower, lowerData: lowerData, upper: upper, upperData: upperData };
    }

    private hexToRgb(hex: string): { r: number, g: number, b: number } {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    private rgbToHex(r: number, g: number, b: number): string {
        let rStr = r.toString(16);
        let gStr = g.toString(16);
        let bStr = b.toString(16);
        
        const rDecimal = rStr.indexOf('.');
        if (rDecimal !== -1)
            rStr = rStr.substr(0, rDecimal);

        const gDecimal = gStr.indexOf('.');
        if (gDecimal !== -1)
            gStr = gStr.substr(0, gDecimal);

        const bDecimal = bStr.indexOf('.');
        if (bDecimal !== -1)
            bStr = bStr.substr(0, bDecimal);
        
        if (rStr.length == 1)
            rStr = "0" + rStr;
        if (gStr.length == 1)
            gStr = "0" + gStr;
        if (bStr.length == 1)
            bStr = "0" + bStr;
        
        return "#" + rStr + gStr + bStr;
    }

    // public static string ConvertHex(Color color) => string.Format('#{0}', color.ToHex().Substring(3));
}
